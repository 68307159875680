import React from 'react'
import styled from 'styled-components'

import Logo from '../styles/Logo'
import MailIcon from '../styles/MailIcon'
import PhoneIcon from '../styles/PhoneIcon'

import { Link } from 'gatsby'
import { StaticQuery, graphql } from "gatsby"

const StyledFooter = styled.footer`
    .flex-container {
        padding: ${props => props.theme.spacingXLarge} ${props => props.theme.spacingXLarge} 4rem ${props => props.theme.spacingXLarge};
        display: flex;

        div {
            width: 66.66666%;

            &:last-of-type {
                width: auto;
                flex-shrink: 0;
            }
        }
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }

    a {
        display: block;
    }

    @media (max-width: ${props => props.theme.breakpoints.xl}) {
      .flex-container {
        padding: 4.5rem ${props => props.theme.spacingXBase} 3.5rem ${props => props.theme.spacingXBase};
      }

      .logo {
        width: 380px;
        height: auto;
      }
    }

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      .logo {
        width: 320px;
        height: auto;
      }
    } 

    @media (max-width: ${props => props.theme.breakpoints.md}) {
        .flex-container {
          padding: 3rem ${props => props.theme.spacingXsm} 1.5rem ${props => props.theme.spacingXsm};
        }

        .logo {
            width: 280px;
            height: auto;
        }
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
        .flex-container {
          display: block;
          padding: 1.5rem ${props => props.theme.spacingXxs} 0rem ${props => props.theme.spacingXxs};
        }

        .logo {
            width: 240px;
            height: auto;
            margin-bottom: 1.5rem;
        }
    }
`

const StyledImageDiv = styled.div`
    width: 100%;
    height: 700px;
    overflow: hidden;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      height: 560px;
    } 

    @media (max-width: ${props => props.theme.breakpoints.md}) {
      height: 420px;
    }

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      height: 350px;
    }

    @media (max-width: ${props => props.theme.breakpoints.xs}) {
      height: 250px;
    }
`

export default function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query MapQuery {
          map: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/kontakt/"}}) {
            edges {
              node {
                frontmatter {
                  marker
                  einstieg
                  adresse
                  telefon
                  email
                  bild {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <StyledFooter>
            <StyledImageDiv>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2656.037307405148!2d13.995231451601766!3d48.26365657913178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47738d4c0561e6cf%3A0xb527362960b8bc6b!2sEnergietechnik%20Schiefermayr!5e0!3m2!1sde!2sat!4v1592827735109!5m2!1sde!2sat" title="Standort" width="100%" height="100%" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false"></iframe>
            </StyledImageDiv>

            <div className="flex-container">
                <div>
                    <Logo></Logo>
                </div>
                <div>
                    <h4>{ data.map.edges[0].node.frontmatter.marker }</h4>
                    <h3
                      key={`h3`}
                      id="___gatsby"
                      dangerouslySetInnerHTML={{ __html: data.map.edges[0].node.frontmatter.einstieg }}
                    />
                    <div>
                        <a css={`display: inline-block!important; margin-right: 1rem;`} href={"mailto:" + data.map.edges[0].node.frontmatter.email}>
                            <MailIcon></MailIcon>
                        </a>
                        <a css={`display: inline-block!important;`} href={"tel:" + data.map.edges[0].node.frontmatter.telefon}>
                            <PhoneIcon></PhoneIcon>
                        </a>
                    </div>

                    <p>{ data.map.edges[0].node.frontmatter.adresse } <br></br>
                        <a css={`display: inline-block!important; margin-right: 1rem;`} href={"mailto:" + data.map.edges[0].node.frontmatter.email}>
                          { data.map.edges[0].node.frontmatter.email }
                        </a> <br/>
                        <a css={`display: inline-block!important;`} href={"tel:" + data.map.edges[0].node.frontmatter.telefon}>
                          { data.map.edges[0].node.frontmatter.telefon }
                        </a>
                    </p>

                    <Link to="/ueber-uns/">Über uns</Link>
                    <Link to="/leistungen/">Leistungen</Link>
                    <Link to="/referenzen/">Referenzen</Link>
                    <Link to="/kontakt/">Kontakt</Link>
                    <Link to="/impressum/">Impressum & Datenschutz</Link>

                    <p>© {new Date().getFullYear()}{` `} Energietechnik Schiefermayr</p>
                </div>
            </div>
        </StyledFooter>
      )}
    />
  )
}
