import React from "react"

import Layout from "../components/layout"
import MailIcon from '../styles/MailIcon'
import PhoneIcon from '../styles/PhoneIcon'

import { graphql } from "gatsby"
import Img from "gatsby-image"
import Map from "../components/map"
import Contact from "../components/contact"
import SEO from "../components/seo"

import remark from 'remark'
import remarkHTML from 'remark-html'

import styled from "styled-components"

const toHTML = value => remark()
                    .use(remarkHTML)
                    .processSync(value)
                    .toString()

const StyledPortrait = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: ${props => props.theme.spacingBase};

  div {
    width: 100%;
  }

  div:first-child {
    margin-right: 8rem;
  }

  p {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: block;
    margin-top: ${props => props.theme.spacingXxs};

    div {
      width: 100%;
    }

    div:first-child {
      margin-right: 0rem;
    }
  }
`

export default function ContactPage({ data, location }) {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <Contact>
        <h4>{ data.kontakt.edges[0].node.frontmatter.marker }</h4>
        <h2
          dangerouslySetInnerHTML={{ __html: data.kontakt.edges[0].node.frontmatter.einstieg }}
        />
        <div>
          <div>
            <a css={`display: inline-block!important; margin-right: 1rem;`} href={"mailto:" + data.kontakt.edges[0].node.frontmatter.email}>
              <MailIcon></MailIcon>
            </a>
            <a css={`display: inline-block!important;`} href={"tel:" + data.kontakt.edges[0].node.frontmatter.telefon}>
              <PhoneIcon></PhoneIcon>
            </a>
          </div>

          <p>{ data.kontakt.edges[0].node.frontmatter.adresse } <br></br>
            <a css={`display: inline-block!important; margin-right: 1rem;`} href={"mailto:" + data.kontakt.edges[0].node.frontmatter.email}>
              { data.kontakt.edges[0].node.frontmatter.email }
            </a> <br/>
            <a css={`display: inline-block!important;`} href={"tel:" + data.kontakt.edges[0].node.frontmatter.telefon}>
              { data.kontakt.edges[0].node.frontmatter.telefon }
            </a>
          </p>

          <h4>Weitere Kontakte</h4>

          <StyledPortrait>
            <div>
              <Img fluid={data.kontakt.edges[0].node.frontmatter.portrait.childImageSharp.fluid} />
              
              <div
                dangerouslySetInnerHTML={{ __html: toHTML(data.kontakt.edges[0].node.html) }}
              />
            </div>
            <div></div>
          </StyledPortrait>
        </div>
      </Contact>

      <Map></Map>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    kontakt: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/kontakt/"}}) {
      edges {
        node {
          frontmatter {
            marker
            einstieg
            adresse
            telefon
            email
            portrait {
              childImageSharp {
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
        }
      }
    }
  }
`
